@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@500;800&family=Roboto:wght@500&family=Ubuntu:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@100..900&family=Noto+Serif+Tamil:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,254,243);
background: linear-gradient(90deg, rgba(255,254,243,1) 0%, rgba(238,255,252,1) 25%, rgba(228,255,250,1) 66%, rgba(255,254,241,1) 100%);}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
